<template>
  <div
    style="position: relative; max-height: 100%"
    id="scroll-target"
    class="overflow-y-auto"
  >
    <v-slide-y-transition>
      <v-btn
        z-index="1000"
        v-scroll="onScroll"
        v-show="fab"
        fab
        dark
        small
        color="primary"
        style="position: fixed; bottom: 20px; right: 20px; z-index: 999"
        @click="$vuetify.goTo(0)"
      >
        <v-icon>mdi-arrow-up-bold-circle</v-icon>
      </v-btn>
    </v-slide-y-transition>

    <v-container v-scroll:#scroll-target="onScroll">
      <v-row class="mb-4">
        <div class="title">
          <v-icon class="mr-5" medium @click="goBack()">mdi-arrow-left</v-icon
          >{{ printTitle() }}
        </div>
        <v-layout v-if="!$route.query.applicant" justify-end>
          <v-btn
            v-show="!isReadOnly"
            class="mr-3 gradient-error caption"
            dark
            depressed
            @click="cancel()"
            >{{ $t("app.cancel") }}
            <v-icon class="ml-2">mdi-close-circle</v-icon></v-btn
          >
          <v-btn
            v-if="$route.query.id ? true : false"
            :color="isReadOnly ? 'gradient-primary-dark' : 'gradient-primary'"
            depressed
            class="error mr-3 caption"
            dark
            @click="saveEdit()"
            >{{ isReadOnly ? $t("app.edit") : $t("app.save") }}
            <v-icon v-if="isReadOnly" class="ml-2">mdi-border-color</v-icon
            ><v-icon v-if="!isReadOnly" class="ml-2"
              >mdi-check-circle</v-icon
            ></v-btn
          >
        </v-layout>
      </v-row>
      <v-stepper v-model="stepPosition">
        <v-stepper-header>
          <v-stepper-step
            :complete="stepPosition > 1"
            :rules="[() => g_ppdb_guru.completed]"
            :editable="isReadOnly"
            step="1"
            >{{
              isTeacher ? $t("ppdb.teacher.tab1") : $t("ppdb.teacher.switch")
            }}</v-stepper-step
          >
          <v-divider />
          <v-stepper-step
            :complete="stepPosition > 2"
            :rules="[() => g_ppdb_riwayat.completed]"
            :editable="isReadOnly"
            step="2"
            >{{ $t("ppdb.teacher.tab2") }}</v-stepper-step
          >
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form ref="guru" lazy-validation>
              <div class="mb-3 d-flex justify-center" v-if="loading">
                <v-progress-circular size="30" color="primary" indeterminate />
              </div>
              <div class="mb-3" v-else>
                <Teachers :isEdit="isReadOnly" :is-valid="formValidation" />
              </div>
              <v-row no-gutters class="mb-5 ml-3">
                <v-btn
                  dark
                  depressed
                  color="gradient-primary-dark"
                  @click="nextStep(1, 'guru')"
                >
                  {{ $t("app.next") }}
                </v-btn>
                <v-spacer />
              </v-row>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-form ref="riwayat" lazy-validation>
              <div class="mb-3">
                <EducationalHistory
                  :is-valid="formValidation"
                  :isEdit="isReadOnly"
                />
              </div>
              <v-row no-gutters class="mb-5 ml-3">
                <v-btn
                  outlined
                  color="primary"
                  class="ml-2"
                  @click="stepPosition = 1"
                  >{{ $t("app.back") }}</v-btn
                >
                <v-btn
                  v-if="!$route.query.id"
                  dark
                  depressed
                  color="gradient-primary-dark"
                  @click="save('riwayat')"
                >
                  {{ $t("app.save") }}
                </v-btn>
                <v-spacer />
              </v-row>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-container>
  </div>
</template>

<script>
import { Teachers, EducationalHistory } from "@/views/ppdb/components";
import { mapGetters } from "vuex";
import { isFormValid } from "@/utils/validate";
import { getByID, employeeUpdate } from "@/api/admin/master/employee";
import { insert_ppdb_guru } from "@/api/homepage/ppdb";
import { getApplicantByID } from "@/api/admin/master/applicant";

export default {
  name: "PpdbGuru",
  props: {
    isTeacher: {
      type: Boolean,
      required: true
    }
  },
  computed: mapGetters([
    "g_ppdb_guru",
    "g_ppdb_riwayat",
    "g_ppdbguru",
    "g_master_data"
  ]),
  components: {
    Teachers,
    EducationalHistory
  },
  data() {
    return {
      fab: false,
      stepPosition: 1,
      steps: 2,
      isReadOnly: this.$route.query.id ? true : false,
      loading: false
    };
  },
  created() {
    if (this.$route.query.id) {
      this.initData();
      this.$store.commit("SET_PANEL_GURU", {
        tab: "riwayat",
        val: [0, 1, 2, 3, 4]
      });
    }
    this.$store.dispatch("getMasterData");
    this.$store.commit("SWITCH_GURU_OR_STAFF", this.isTeacher);
  },
  watch: {
    steps(val) {
      if (this.stepPosition > val) {
        this.stepPosition = val;
      }
    }
  },
  destroyed() {
    this.$store.commit("RESET_PPDB_GURU");
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 150;
    },
    printTitle() {
      const q = this.$route.query;
      if (this.isTeacher) {
        if (q.id && !q.applicant) {
          return this.$i18n.t("ppdb.teacher.edit_manual");
        } else if (q.id && q.applicant) {
          return this.$i18n.t("app.teacher_detail");
        }
        return this.$i18n.t("ppdb.teacher.add_manual");
      } else {
        if (q.id && !q.applicant) {
          return this.$i18n.t("ppdb.teacher.edit_manual_staff");
        } else if (q.id && q.applicant) {
          return this.$i18n.t("app.staff_detail");
        }
        return this.$i18n.t("ppdb.teacher.add_manual_staff");
      }
    },
    stringYearMonth: date => (date.length === 10 ? date.substr(0, 7) : date),
    initData() {
      this.loading = true;
      if (this.$route.query.applicant) {
        getApplicantByID({ id: this.$route.query.id }).then(d => {
          if (d.data.code) {
            const row = d.data.data;
            const tempData = JSON.parse(JSON.stringify(row));
            this.$store.commit("SET_DATA_EMPLOYEE", tempData);
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: d.data.message,
              color: "error"
            });
          }
          this.loading = false;
        });
      } else {
        getByID({ id: this.$route.query.id }).then(d => {
          if (d.data.code) {
            const row = d.data.data;
            const tempData = JSON.parse(JSON.stringify(row));

            tempData.guru.riwayat.pengalaman_kerja.map(item => {
              item.tahun_keluar = this.stringYearMonth(item.tahun_keluar);
              item.tahun_masuk = this.stringYearMonth(item.tahun_masuk);
            });

            this.$store.commit("SET_DATA_EMPLOYEE", tempData);
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: d.data.message,
              color: "error"
            });
          }
          this.loading = false;
        });
      }
    },
    saveEdit() {
      if (this.isReadOnly) {
        this.isReadOnly = false;
      } else {
        const isValidate = ["guru", "riwayat"].map(d => {
          if (!this.$refs[d].validate()) {
            this.$store.commit("SET_COMPLETE_TAB_GURU", {
              status: false,
              param: d
            });
            this.$vuetify.goTo(140);
            return false;
          }
          return true;
        });

        if (!isValidate.includes(false)) {
          this.changeDate();
          this.$store.commit("TOGGLE_FULL_LOADING");
          const postEdit = {
            employee: this.g_ppdbguru.employee,
            guruOrStaff: this.g_ppdbguru.guruOrStaff,
            auto_accept: this.g_ppdbguru.auto_accept,
            id_sekolah: this.g_ppdbguru.id_sekolah,
            guru: {
              guru: this.g_ppdbguru.guru,
              riwayat: this.g_ppdbguru.riwayat
            }
          };

          employeeUpdate(postEdit)
            .then(() => {
              this.$store.commit("TOGGLE_FULL_LOADING");
              this.goBack();
            })
            .catch(() => {
              this.$store.commit("TOGGLE_FULL_LOADING");
            });
        }
      }
    },
    cancel() {
      if (!this.$route.query.id) {
        this.goBack();
      } else {
        this.isReadOnly = true;
        this.initData();
      }
    },
    goBack() {
      const path = this.isTeacher ? "teacher" : "staff";
      this.$router.push("/master/" + path);
      this.$store.commit("RESET_PPDB_GURU");
    },
    formValidation(text, type) {
      return isFormValid(text, type);
    },
    closeModalRegisterDone() {
      this.modalRegisterDone = false;
    },
    onInput(val) {
      this.steps = parseInt(val);
    },
    changeDate() {
      if (this.g_ppdbguru.riwayat.pengalaman_kerja.length > 0) {
        this.g_ppdbguru.riwayat.pengalaman_kerja.map(d =>
          d.tahun_masuk.length < 10
            ? (d.tahun_masuk = d.tahun_masuk.concat("-01"))
            : d.tahun_masuk
        );
        this.g_ppdbguru.riwayat.pengalaman_kerja.map(d =>
          d.tahun_keluar?.length < 10
            ? (d.tahun_keluar = d.tahun_keluar.concat("-01"))
            : d.tahun_keluar
        );
      }
    },
    save(tab) {
      if (this.$refs[tab].validate()) {
        this.$store.commit("TOGGLE_FULL_LOADING");
        this.changeDate();
        this.$store.commit("AUTO_ACCEPT_EMPLOYEE");
        this.$store.commit("SET_COMPLETE_TAB_GURU", {
          status: true,
          param: tab
        });
        insert_ppdb_guru(this.g_ppdbguru)
          .then(res => {
            const r = res.data;
            if (r.code) {
              this.$store.commit("TOGGLE_FULL_LOADING");
              this.$store.commit("CALL_SNACKBAR", {
                msg: this.$i18n.t("app.success_save"),
                color: "success"
              });
              if (r.data) {
                this.goBack();
              }
            } else {
              this.$store.commit("TOGGLE_FULL_LOADING");
              this.$store.commit("CALL_SNACKBAR", {
                msg: r.message,
                color: "error",
                x: "right",
                y: "top"
              });
            }
          })
          .catch(err => {
            if (err) this.$store.commit("TOGGLE_FULL_LOADING");
          });
      } else {
        this.$store.commit("SET_COMPLETE_TAB_GURU", {
          status: false,
          param: tab
        });
        this.$vuetify.goTo(140);
      }
    },
    nextStep(n, tab) {
      if (n === this.steps) {
        this.stepPosition = 1;
      } else {
        switch (tab) {
          case "guru":
            this.$store.commit("SET_PANEL_GURU", { tab: "guru", val: [0, 1] });
            break;
        }

        setTimeout(() => {
          if (this.$refs[tab].validate() || this.isReadOnly) {
            this.stepPosition = n + 1;
            this.$store.commit("SET_COMPLETE_TAB_GURU", {
              status: true,
              param: tab
            });
          } else {
            this.$store.commit("SET_COMPLETE_TAB_GURU", {
              status: false,
              param: tab
            });
            this.$vuetify.goTo(140);
          }
        }, 200);
      }
      this.$vuetify.goTo(140);
    },
    prevStep(n) {
      if (n === 1) {
        this.stepPosition = 1;
      } else {
        this.stepPosition = n - 1;
      }
      this.$vuetify.goTo(150);
    }
  }
};
</script>
